import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { debounce } from "lodash";
import { getRequest, postRequest } from "../interceptor/interceptor";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { IoPieChart } from "react-icons/io5";
import { Link } from "react-router-dom";
import { IoBarChart } from "react-icons/io5";
import { IoStatsChartSharp } from "react-icons/io5"
import {
  Grid,
  Card,
  Paper,
  Container,
  CardHeader,
  Typography,
} from "@mui/material";
import { DASHBOARD_STATS} from "../../apiConst";
import CompaniessList from "./CompaniesList.jsx";
import {
  dashboardTableHeader,
  grievanceTableHeader,
} from "../../fields/grievanceFields.js";
import SelectDropdown from "../../Common/SelectDropdown";
import DateRangePicker from "../../Common/DateRangePicker.jsx";
import Barchart from "./Barchart.jsx";
import Circularchart from "./Circularchart.jsx";
import NavigationTabs from "./NavigationTabs.jsx";

const Dashboard = () => {
  const {
    register,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const headers = dashboardTableHeader;

  const FilterOptions = grievanceTableHeader;

  const userData = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(rowsPerPage);
  const [tableRowsData, setTableRowsData] = useState([]);
  const [filterByText, setFilterByText] = useState("");
  const [tabsData, setTabsData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [newCount, setNewCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [resolvedCount, setResolvedCount] = useState(0);
  const [rejectedCount, setRejectedCount] = useState(0);
  const [newTicketsData, setNewTicketsData] = useState([]);
  const [inProgressData, setInProgressData] = useState([]);
  const [resolvedData, setResolvedData] = useState([]);
  const [rejectedData, setRejectedData] = useState([]);
  const navigate = useNavigate();
  const [searchVal, setSearchVal] = useState("");
  const [requestMode, setRequestMode] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const [searchField, setSearchField] = useState("");
  const [dataState, setDataState] = useState();
  const [pivotdata, setPivotdata] = useState([]);
  const dispatch = useDispatch();
  const [requestBarMode, setRequestBarMode] = useState("state");
  const [requestCircularMode, setRequestCircularMode] = useState("state");
  useEffect(() => {
    fetchListData();
  }, [startDate, endDate]);

  const fetchListData = (
    pg = 1,
    size = 10,
    search = "",
    selectedRequestMode = requestMode,
    selectedRequestType = requestType,
    searchKey = null
  ) => {
    setIsLoading(true);

    let payload = {
      // page: pg,
      // pageSize: size,
    };

    // Conditionally add values if they are changed
    if (startDate) payload.startDate = moment(startDate).format("YYYY-MM-DD");
    if (endDate) payload.endDate = moment(endDate).format("YYYY-MM-DD");
    if (selectedRequestMode && selectedRequestMode!=='select') payload.requestMode = selectedRequestMode;
    if (selectedRequestType && selectedRequestType !=='select') selectedRequestMode==="private" ?payload.privateRequestType = selectedRequestType:payload.requestType = selectedRequestType;

    const { searchField } = getValues();
    const key = searchKey || searchField;

    if (key && search) {
      payload[key] = search;
    }
    postRequest(DASHBOARD_STATS, payload)
      .then((res) => {
        console.log("res", res);
        const {
          newTicket_count,
          inprogress_count,
          resolved_count,
          rejected_count,
          total,
          newTicket,
          inprogress,
          rejected,
          resolved,
        } = res.data;
        setPivotdata(res.data);
        // setTableRowsData(result || []);
        setTotalCount(total);
        setNewCount(newTicket_count);
        setInProgressCount(inprogress_count);
        setResolvedCount(resolved_count);
        setNewTicketsData(newTicket || []);
        setInProgressData(inprogress || []);
        setResolvedData(resolved || []);
        setRejectedCount(rejected_count);
        setRejectedData(rejected || []);
        setIsLoading(false);
        setIsDisplay(true);
      })
      .catch(() => {
        setIsLoading(false);
        setNewCount(0);
        setInProgressCount(0);
        setResolvedCount(0);
        setRejectedCount(0);
        setNewTicketsData([]);
        setInProgressData([]);
        setResolvedData([]);
        setRejectedData([]);
        setTotalCount(0);
      });
  };

  const onEdit = (task) => {
    navigate("/stepper-form", {
      state: {
        data: {
          task: {
            ...task,
          },
        },
        isEdit: true,
      },
    });
  };
  const onView = (task) => {
    navigate("/stepper-form", {
      state: {
        data: {
          task: {
            ...task,
          },
        },
        isView: true,
      },
    });
  };
  const onStepperForm = (task) => {
    navigate("/stepper-form", {
      state: {
        data: {
          task: {
            ...task,
          },
        },
        isView: false,
      },
    });
  };
  
  const handleSearchFieldDropdown = (e) => {
    const selectedField = e.target.value;
    setValue("searchField", selectedField);
    setSearchField(selectedField);
    fetchListData(1, rowsPerPage, searchVal, requestMode, selectedField);
  };

  const handleRequestModeDropdown = (e) => {
    const selectedMode = e.target.value
    console.log("selectedMode", selectedMode);
    setValue("requestMode", selectedMode);
    setRequestMode(selectedMode);
    fetchListData(1, rowsPerPage, searchVal, selectedMode);
    setRequestCircularMode(selectedMode==='select'?'state':selectedMode);
    setRequestBarMode(selectedMode==='select'?'state':selectedMode);
  };

  const handleRequestTypeDropdown = (e) => {
    const selectedType = e.target.value
    setValue("requestType", selectedType);
    setRequestType(selectedType);
    fetchListData(1, rowsPerPage, searchVal, requestMode, selectedType);
    setRequestCircularMode(requestMode==='select'?'state':requestMode);
    setRequestBarMode(requestMode==='select'?'state':requestMode);
  };

  const debounceSearch = debounce((searchValue) => {
    setSearchVal(searchValue);
    fetchListData(
      1,
      rowsPerPage,
      searchValue,
      requestMode,
      requestType,
      searchField
    );
  }, 750);

  const handleChangeSearch = (e) => {
    debounceSearch(e.target.value);
  };

  const cancelSearch = () => {
    setSearchVal("");
    fetchListData(1, rowsPerPage, "");
  };

  const componentDisplay = () => {
    const cells = FilterOptions?.filter(
      (cell) =>
        cell.id === "ticketNo" ||
        cell.id === "requestTitle" ||
        cell.id === "applicantName" ||
        cell.id === "ticketCreatedBy" ||
        cell.id === "assignedTo" ||
        cell.id === "referralName"
    );
    return (
      <div className="text-center mb-[-15px]">
        {FilterOptions.length > 0 && (
          <SelectDropdown
            fieldName="searchField"
            options={cells}
            optionsLabelKey="label"
            optionsValueKey="id"
            register={register}
            onChange={handleSearchFieldDropdown}
            errors={errors}
            fieldType="select"
            className="min-h-[auto] w-full border-1 bg-light py-1.5 text-sm text-gray-900 bg-white-900 rounded-md pl-2 focus:outline-none focus:bg-white focus:text-gray-900"
            emptyOption={false}
          />
        )}
      </div>
    );
  };
  console.log("pivotdata", pivotdata);
  const taskNavTabs = [
    {
      index: 1,
      title: 'New',
    },
    {
      index: 2,
      title: 'In Progress',
    },
    {
      index: 3,
      title: 'Rejected',
    },
    {
      index: 4,
      title: 'Resolved',
    },
  ];

  const stats = [
    { label: "Request Mode", value: requestMode==="select"?"NA":requestMode || "NA", color: "" },
    // { label: "Request Type", value: requestType || "NA" },
    { label: "New Tickets", value: newCount || 0, color: "#2f4b7c" },
    { label: "In Progress", value: inProgressCount || 0, color: "#f28927" },
    { label: "Rejected", value: rejectedCount || 0, color: "#c44627" },
    { label: "Resolved", value: resolvedCount || 0, color: "#5D883C" },
  ];

  return (
    <div className="w-full px-3 lg:px-8">
      <Grid container spacing={1} className="p-2">
        <Grid item xs={12}>
          <div elevation={2} className="p-2 bg-blueBg rounded font-montsettat">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={2.4}>
                <label className="block text-white font-medium text-sm mb-1">
                  Select Date
                </label>
                <DateRangePicker
                  setEndDate={setEndDate}
                  setStartDate={setStartDate}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2.4}>
                <label className="block text-white font-medium text-sm mb-1">
                  Request Mode
                </label>
                <SelectDropdown
                  fieldName="requestMode"
                  options={[
                    { label: "Select", value: "" },
                    { label: "State", value: "state" },
                    { label: "Central", value: "central" },
                    { label: "Private", value: "private" },
                  ]}
                  optionsLabelKey="label"
                  optionsValueKey="value"
                  register={register}

                  onChange={handleRequestModeDropdown}
                  errors={errors}
                  fieldType="select"
                  className="w-full border bg-white py-1.5 text-sm text-gray-900 rounded-md pl-2 focus:outline-none focus:bg-white focus:text-gray-900"
                  emptyOption={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2.4}>
                <label className="block text-white font-medium text-sm mb-1">
                  Request Type
                </label>
                <SelectDropdown
                  fieldName={requestMode === "private"? "privateRequestType":"requestType"}
                  options={
                    requestMode === "private"
                      ? [
                          { label: "Select", value: "" },
                          {
                            label: "Employment",
                            value: "Employment",
                          },
                          {
                            label: "Help/Assistance",
                            value: "Help/Assistance",
                          },
                          {
                            label: "Other",
                            value: "other",
                          },
                        ]
                      : [
                          { label: "Select", value: "" },
                          { label: "Personal", value: "personal" },
                          { label: "Community", value: "community" },
                          { label: "Official", value: "official" },
                          { label: "Medical", value: "medical" },
                          { label: "Other", value: "other" },
                        ]
                  }
                  optionsLabelKey="label"
                  optionsValueKey="value"
                  register={register}
                  onChange={handleRequestTypeDropdown}
                  errors={errors}
                  fieldType="select"
                  className="w-full border bg-white py-1.5 text-sm text-gray-900 rounded-md pl-2 focus:outline-none focus:bg-white focus:text-gray-900"
                  emptyOption={false}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2.4}>
                <label className="block text-white font-medium text-sm mb-1">
                  Filter by
                </label>
                {componentDisplay ? (
                  componentDisplay()
                ) : (
                  <div className="h-10 bg-gray-100 rounded-md"></div>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={2.4}>
                <label className="block text-white font-medium text-sm mb-1">
                  Search
                </label>
                <div className="relative">
                  <span className="absolute inset-y-0 left-3 flex items-center">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-5 h-5 text-gray-500"
                    >
                      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                  </span>
                  <input
                    type="search"
                    name="search"
                    onChange={(e) => handleChangeSearch(e)}
                    className="w-full py-1 pl-10 pr-4 border border-gray-500 rounded-md focus:border-primary-500 shadow-sm"
                    placeholder="Search..."
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        {/* Stats Section (Cards) */}
        <Grid item xs={12}>
          <Card elevation={2} className="p-3 rounded-lg ">
            <Grid container spacing={2}>
              {stats.map((stat, index) => (
                <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                  <div className="text-center">
                    <Typography
                      variant="subtitle1"
                      className="text-gray-800 font-medium mb-1 font-montsettat"
                    >
                      {stat.label}
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{ color: stat.color }}
                      className={`font-extrabold capitalize font-montsettat`}
                    >
                      {stat.value < 10
                        ? `0${stat.value || 0}`
                        : stat.value || 0}
                    </Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Card>
        </Grid>

        
        <Grid item xs={12}>
          <Card elevation={2} className="p-2 bg-white rounded-lg">
            
            <NavigationTabs
              navTabs={taskNavTabs}
            //   heading="Tickets"
              tab_1={
                <CompaniessList
                  heading={false}
                  tableRowsData={newTicketsData}
                  getListData={fetchListData}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  onEdit={onEdit}
                  onView={onView}
                  onStepperForm={onStepperForm}
                  register={register}
                  errors={errors}
                />
              }
              tab_2={
                <CompaniessList
                  heading={false}
                  tableRowsData={inProgressData}
                  getListData={fetchListData}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  onEdit={onEdit}
                  onView={onView}
                  onStepperForm={onStepperForm}
                  register={register}
                  errors={errors}
                />
              }
              tab_3={
                <CompaniessList
                  heading={false}
                  tableRowsData={rejectedData}
                  getListData={fetchListData}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  onEdit={onEdit}
                  onView={onView}
                  onStepperForm={onStepperForm}
                  register={register}
                  errors={errors}
                />
              }
              tab_4={
                <CompaniessList
                  heading={false}
                  tableRowsData={resolvedData}
                  getListData={fetchListData}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  totalCount={totalCount}
                  page={page}
                  setPage={setPage}
                  onEdit={onEdit}
                  onView={onView}
                  onStepperForm={onStepperForm}
                  register={register}
                  errors={errors}
                />
              }
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card elevation={2} className="p-2 bg-white rounded-lg">
            <div className="flex flex-wrap justify-between rounded-xl items-center gap-2">
              <Typography
                variant="h6"
                className="text-gray-800 font-semibold flex items-center"
              >
                <IoPieChart className="mr-2" /> 
                Companies Chart
              </Typography>

              {/* <div className="flex items-center space-x-2 font-montsettat">
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="radio"
                    value="state"
                    checked={requestCircularMode === "state"}
                    onChange={() => setRequestCircularMode("state")}
                    className="hidden peer"
                    name="requestCircularMode"
                  />
                  <div className="px-3 py-1.5 rounded-full text-sm font-medium border border-gray-300 peer-checked:bg-blueBg peer-checked:text-white peer-checked:border-blueBg transition-all duration-200">
                    State
                  </div>
                </label>

                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="radio"
                    value="central"
                    checked={requestCircularMode === "central"}
                    onChange={() => setRequestCircularMode("central")}
                    className="hidden peer"
                    name="requestCircularMode"
                  />
                  <div className="px-3 py-1.5 rounded-full text-sm font-medium border border-gray-300 peer-checked:bg-blueBg peer-checked:text-white peer-checked:border-blueBg transition-all duration-200">
                    Central
                  </div>
                </label>

                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="radio"
                    value="private"
                    checked={requestCircularMode === "private"}
                    onChange={() => setRequestCircularMode("private")}
                    className="hidden peer"
                    name="requestCircularMode"
                  />
                  <div className="px-3 py-1.5 rounded-full text-sm font-medium border border-gray-300 peer-checked:bg-blueBg peer-checked:text-white peer-checked:border-blueBg transition-all duration-200">
                    Private
                  </div>
                </label>
              </div> */}
            </div>

            <Circularchart
              ticketData={pivotdata}
              requestCircularMode={requestCircularMode}
            />
          </Card>
        </Grid>
        <Grid item xs={12} md={7}>
          <Card elevation={2} className="p-2 bg-white rounded-lg">
            <div className="flex flex-wrap justify-between items-center gap-2">
              <Typography
                variant="h6"
                className="text-gray-800 font-semibold flex items-center"
              >
                <IoStatsChartSharp className="mr-2" /> 
                Companies Stats
              </Typography>

              {/* <div className="flex items-center space-x-2 font-montsettat">
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="radio"
                    value="state"
                    checked={requestBarMode === "state"}
                    onChange={() => setRequestBarMode("state")}
                    className="hidden peer"
                    name="requestBarMode"
                  />
                  <div className="px-3 py-1.5 rounded-full text-sm font-medium border border-gray-300 peer-checked:bg-blueBg peer-checked:text-white peer-checked:border-blueBg transition-all duration-200">
                    State
                  </div>
                </label>

                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="radio"
                    value="central"
                    checked={requestBarMode === "central"}
                    onChange={() => setRequestBarMode("central")}
                    className="hidden peer"
                    name="requestBarMode"
                  />
                  <div className="px-3 py-1.5 rounded-full text-sm font-medium border border-gray-300 peer-checked:bg-blueBg peer-checked:text-white peer-checked:border-blueBg transition-all duration-200">
                    Central
                  </div>
                </label>

                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="radio"
                    value="private"
                    checked={requestBarMode === "private"}
                    onChange={() => setRequestBarMode("private")}
                    className="hidden peer"
                    name="requestBarMode"
                  />
                  <div className="px-3 py-1.5 rounded-full text-sm font-medium border border-gray-300 peer-checked:bg-blueBg peer-checked:text-white peer-checked:border-blueBg transition-all duration-200">
                    Private
                  </div>
                </label>
              </div> */}
            </div>
            <Barchart
              ticketData={pivotdata}
              requestBarMode={requestBarMode}
            />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
