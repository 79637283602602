import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import Autocomplete from "react-google-autocomplete";
import { useForm } from "react-hook-form";
import InputField from "../../Common/InputField";
import MapMarker from "./MapMarker";
import { get } from "lodash";
import CompanyDisplay from "./CompanyDisplay";
import { getRequest, postRequest } from "../interceptor/interceptor";
import {
  GET_CITY_FROM_IP_ADDRESS,
  GET_DOMAIN_SETTINGS,
  GET_IP_ADDRESS,
} from "../../apiConst";
import axios from "axios";
import { FaArrowRight, FaGripVertical, FaList, FaMap } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import SelectDropdown from "../../Common/SelectDropdown";

const libraries = ["places"];

const distanceOptions = {
  miles: "Miles",
  km: "Km",
};

const MapFeature = ({ getCompanyByLocation, tableRowsData, setIsLoading }) => {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [lat, setLat] = useState(0);
  const [lng, setLng] = useState(0);
  const [filters, setFilters] = useState([]);
  const [viewMode, setViewMode] = useState("map");
  const [placeObj, setPlaceObj] = useState("");
  const [singleView, setSingleView] = useState(false);
  const [mapView, setMapView] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    reset({
      distance: 50,
    });

    getFiltersData();
    getIpAddress();
    setDisplayView();

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition((showPosition, showError) => {
    //     const { latitude, longitude } = showPosition.coords;
    //     if (latitude && longitude) {
    //       setLat(latitude);
    //       setLng(longitude);
    //     } else {
    //       setLat(defaultLatLong[0]);
    //       setLng(defaultLatLong[1]);
    //     }
    //   });

    //   navigator.permissions
    //     .query({
    //       name: "geolocation",
    //     })
    //     .then((permission) => {
    //       if (permission.state !== "granted") {
    //         setLat(defaultLatLong[0]);
    //         setLng(defaultLatLong[1]);
    //       }
    //     });
    // }
  }, []);

  useEffect(() => {
    if (lat && lng) {
      setPayload();
    }
  }, [lat, lng]);

  const setDisplayView = () => {
    console.log(searchParams);
    const viewType = searchParams.get("view");
    if (viewType) {
      if (viewType == "map") {
        setSingleView(true);
        setMapView(true);
      } else if (viewType == "list") {
        setSingleView(true);
        setMapView(false);
      } else {
        setSingleView(false);
        setMapView(false);
      }
    }
  };

  const getLatLongByIpAddress = (ipAddress) => {
    setIsLoading(true);
    getRequest(`${GET_CITY_FROM_IP_ADDRESS}?ip=${ipAddress}`)
      .then((res) => {
        if (res.data) {
          const { latitude, longitude, city, country_name, region_name } =
            res.data;

          setLat(Number(latitude));
          setLng(Number(longitude));
          setPlaceObj({
            city: city,
            state: region_name,
            country: country_name,
          });
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
    // setLat(defaultLatLong[0]);
    // setLng(defaultLatLong[1]);
    // setPlace("Pune");
  };

  const getFiltersData = () => {
    const payload = {
      domain: "www.itpros.tech",
    };
    postRequest(`${GET_DOMAIN_SETTINGS}`, payload)
      .then((res) => {
        setFilters(get(res.data.data[0], "filters.company"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setPayload = () => {
    let obj = getValues();
    const conversionDetail = {
      km: 1000,
      miles: 1609.34,
    };
    console.log(obj);
    obj = {
      ...obj,
      distance: obj.distance ? obj.distance : 5,
      distanceType: obj.distanceType ? obj.distanceType : "miles",
    };
    const payload = {
      filter: {},
      // page: 1,
      // size: 10,
      geo: {
        lat: lat,
        long: lng,
        ...obj,
      },
    };

    getCompanyByLocation(payload);
  };

  const getIpAddress = () => {
    const ipAddress = localStorage.getItem("locationIp");
    if (ipAddress) {
      getLatLongByIpAddress(ipAddress);
    } else {
      setIsLoading(true);
      axios
        .get(`${GET_IP_ADDRESS}`)
        .then((res) => {
          setIsLoading(false);
          if (res.data) {
            const { ip } = res.data;
            getLatLongByIpAddress(ip);
            localStorage.setItem("locationIp", ip);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const {
    register,
    getValues,
    reset,
    formState: { errors },
  } = useForm({});

  const onMapClick = (e) => {
    const { latLng } = e;
    setLat(latLng.lat());
    setLng(latLng.lng());
  };

  const onBlur = (e) => {
    setPayload();
  };

  const setPlaceDetails = (singlePlace) => {
    let obj = {};
    get(singlePlace, "address_components").forEach((addr) => {
      if (get(addr, "types").includes("locality")) {
        obj["city"] = get(addr, "long_name");
      } else if (get(addr, "types").includes("administrative_area_level_1")) {
        obj["state"] = get(addr, "long_name");
      } else if (get(addr, "types").includes("country")) {
        obj["country"] = get(addr, "long_name");
      }
    });
    setPlaceObj(obj);
  };

  const onPlaceChange = (singlePlace) => {
    setPlaceDetails(singlePlace);
    setLat(singlePlace.geometry.location.lat());
    setLng(singlePlace.geometry.location.lng());
  };

  const onMapLoad = () => {
    setMapLoaded(true);
  };

  const mapViewToggle = () => {
    setSingleView(true);
    setMapView(!mapView);
    searchParams.set("view", mapView ? "list" : "map");
    setSearchParams(searchParams);
  };

  const offSingleView = () => {
    setSingleView(false);
    setMapView(false);
    searchParams.set("view", "both");
    setSearchParams(searchParams);
  };

  return (
    <>
    {/* <div className="flex justify-center"> */}
      <div className="space-y-6 px-3 lg:px-10 py-2">
        {/* Filter Section */}
        <div className="bg-white shadow-lg p-6 rounded-lg">
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Filter Results
          </h2>

          {mapLoaded && (
              <form className="space-y-4">
                {/* Location Filter */}
                <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
                  <div className="flex flex-col">
                    <label className="font-medium text-gray-600 mb-2">
                      Location
                    </label>
                    <Autocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                      onPlaceSelected={(singlePlace) =>
                        onPlaceChange(singlePlace)
                      }
                      defaultValue={placeObj?.city}
                      className="border border-gray-300 rounded-lg px-4 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    {placeObj && (
                      <div className="mt-3 flex items-center text-gray-600">
                        {placeObj.country} <FaArrowRight className="mx-2" />
                        {placeObj.state} <FaArrowRight className="mx-2" />
                        <span className="text-blue-600 font-semibold">
                          {placeObj.city}
                        </span>
                      </div>
                    )}
                  </div>

                  {/* Dynamic Filters */}
                  {filters?.map((filter) => (
                    <>
                    <div className="flex flex-col" key={filter.value}>
                      <label className="font-medium text-gray-600 mb-2">
                        {filter.label}
                      </label>
                      <InputField
                        fieldName={filter.value}
                        register={register}
                        onBlur={onBlur}
                        errors={errors}
                        fieldType={filter.type}
                        className="border border-gray-300 rounded-lg px-4 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        placeholder={`Enter ${filter.label}`}
                      />
                      
                    </div>
                    <div className="flex flex-col" key={filter.value}>
                      <label className="font-medium text-gray-600">
                        Distance Type
                      </label>
                      
                      {filter.value === "distance" && (
                        <SelectDropdown
                          fieldName="distanceType"
                          options={distanceOptions}
                          register={register}
                          onChange={onBlur}
                          errors={errors}
                          fieldType="select"
                          className="border border-gray-300 rounded-lg px-4 py-2 text-gray-700 focus:ring-2 focus:ring-blue-500 focus:outline-none mt-2"
                          emptyOption={false}
                        />
                      )}
                    </div>
                    </>
                    
                  ))}
                </div>
              </form>
           
          )}
        </div>

        {/* View Mode Toggle Buttons */}
        <div className="flex justify-center gap-4">
          <button
            onClick={() => setViewMode("map")}
            className={`flex items-center px-6 py-3 rounded-lg font-medium shadow-md transition-all ${
              viewMode === "map"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            <FaMap className="mr-2" /> Map
          </button>
          <button
            onClick={() => setViewMode("details")}
            className={`flex items-center px-6 py-3 rounded-lg font-medium shadow-md transition-all ${
              viewMode === "details"
                ? "bg-blue-600 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            <FaList className="mr-2" /> Details
          </button>
        </div>

        {/* Map View */}
        {viewMode === "map" && (
          <div className="overflow-hidden  rounded-lg shadow-lg">
            <LoadScript
              googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
              libraries={libraries}
            >
              <GoogleMap
                mapContainerStyle={{ height: "60vh", width: "100%" }}
                center={{ lat, lng }}
                zoom={12}
                onLoad={onMapLoad}
                onClick={onMapClick}
              >
                {tableRowsData?.map((place, index) => {
                  const coords = place?.location?.coordinates;
                  return (
                    coords?.length > 0 && (
                      <MapMarker
                        key={place._id + index}
                        position={{ lat: coords[1], lng: coords[0] }}
                        place={place}
                      />
                    )
                  );
                })}
              </GoogleMap>
            </LoadScript>
          </div>
        )}

        {/* Details View */}
        {viewMode === "details" && (
          <CompanyDisplay tableRowsData={tableRowsData} />
        )}
      </div>
      {/* </div> */}
    </>
  );
};

export default MapFeature;
