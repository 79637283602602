import React from "react";
import * as te from "tw-elements";
import Navbar from "./Components/Navbar/navbar";
import Routing from "./routes";
import Footer from "./Components/footer/Footer";
import { useLocation } from "react-router";
function App() {
  const location =useLocation()
  return (
    <>
      <Navbar />
      <div className="bg-blue-50 min-h-screen h-full w-full">
      <Routing />
      </div>

      {location.pathname==='/' &&<Footer/>}
      
    </>
  );
}

export default App;
