import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

const Barchart = ({ ticketData = {},requestBarMode }) => {
  console.log("ticketData", ticketData);

  const transformTicketData = (apiData = {}, requestBarMode) => {
    const newTicket = Array.isArray(apiData.newTicket) ? apiData.newTicket : [];
    const inprogress = Array.isArray(apiData.inprogress) ? apiData.inprogress : [];
    const rejected = Array.isArray(apiData.rejected) ? apiData.rejected : [];
    const resolved = Array.isArray(apiData.resolved) ? apiData.resolved : [];
  
    const groupedData = {};
  
    // Helper function to process tickets and count by status
    const processTickets = (tickets, status) => {
      if (!Array.isArray(tickets)) return;
  
      tickets
        .filter((ticket) => ticket.requestMode === requestBarMode)
        .forEach((ticket) => {
          const requestType = requestBarMode==='private'?ticket.privateRequestType || "Unknown":ticket.requestType || "Unknown";
  
          if (!groupedData[requestType]) {
            groupedData[requestType] = {
              name: requestType,
              New: 0,
              InProgress: 0,
              Rejected: 0,
              Resolved: 0,
            };
          }
  
          groupedData[requestType][status] += 1;
        });
    };
  
    processTickets(newTicket, "New");
    processTickets(inprogress, "InProgress");
    processTickets(rejected, "Rejected");
    processTickets(resolved, "Resolved");
  
    return Object.values(groupedData);
  };
  const transformedData = transformTicketData(ticketData,requestBarMode);
  console.log("Transformed Data:", transformedData);

  const COLORS = {
    New: "#2f4b7c",
    InProgress: "#f28927",
    Rejected: "#c44627",
    Resolved: "#5D883C",
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white border rounded shadow px-4 py-2 text-sm text-gray-800">
          {payload.map((entry, index) => (
            <div key={index} className="mb-1">
              <span
                className="inline-block w-3 h-3 rounded-full mr-2"
                style={{ backgroundColor: entry.color }}
              ></span>
              <strong>{entry.name}:</strong> {entry.value}
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  // Custom Legend
  const CustomLegend = ({ payload }) => {
    return (
      <div className="flex flex-wrap justify-center gap-4 text-sm text-gray-800 py-4">
        {payload?.map((entry, index) => (
          <div key={index} className="flex items-center space-x-2">
            <div
              className="w-3 h-3 rounded-full"
              style={{ backgroundColor: entry.color }}
            ></div>
            <span>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="w-full max-w-5xl mx-auto px-4 py-6">
      <div className="w-full h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={transformedData}
            margin={{ top: 20, right: 30, left: 20, bottom: 40 }}
          >
            <XAxis
              dataKey="name"
              tick={{ angle: 0, textAnchor: "middle" }}
              tickLine={false}
              stroke="#888"
            />
            <YAxis
              tickFormatter={(tick) => Math.round(tick)}
              allowDecimals={false}
              tickLine={false}
              stroke="#888"
            />
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ fill: "rgba(0,0,0,0.05)" }}
            />
            <Legend content={<CustomLegend />} />

            {Object.keys(COLORS).map((key) => (
              <Bar
                key={key}
                dataKey={key}
                stackId="a"
                fill={COLORS[key]}
                barSize={80}
                radius={[2, 2, 0, 0]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Barchart;
