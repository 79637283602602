import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import { FaGoogle, FaFacebook, FaApple } from "react-icons/fa";
import { GENERATE_OTP, VERIFY_OTP } from "../../apiConst";
import InputField from "../../Common/InputField";
import { postRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { toast } from "react-toastify";
import Navbar from "../Navbar/navbar";
import bannerBg from "../../images/bannerBg.png";

const Login = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [otpFieldDisplay, setOtpFieldDisplay] = useState(false);
  const [OTP, setOTP] = useState();
  const dispatch = useDispatch();
  const OTP_LENGTH = 4;

  const handleChange = (value) => {
    setOTP(value);
    // if (value.length === OTP_LENGTH) {
    //   // Automatically submit OTP when complete
    //   loginViaEmailAndOTP({ email, otp: value });
    // }
  };
  const [googleUser, setGoogleUser] = useState([]);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (googleUser && googleUser.access_token) {
      setIsLoading(true);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${googleUser.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setIsLoading(false);
          setProfile(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  }, [googleUser]);

  useEffect(() => {
    if (profile) {
      const data = {
        email: profile.email,
      };
      loginViaEmailAndOTP(data);
    }
  }, [profile]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});

  let navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("userAuthToken")) {
      navigate(-1);
    }
  });

  const loginViaEmailAndOTP = (data) => {
    if (otpFieldDisplay) {
      data = {
        ...data,
        email: email,
      };
      console.log("data", data);
      postRequest(`${VERIFY_OTP}`, data)
        .then((res) => {
          reset();
          toast.success("Login Successfully");
          console.log(res);
          const { token } = res.data.data;
          if (token) {
            localStorage.setItem("userAuthToken", token);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          err.data && err.data.message && toast.error(err.data.message);
          console.log(err);
        });
    } else {
      setEmail(data.email);
      postRequest(`${GENERATE_OTP}`, data)
        .then((res) => {
          setOtpFieldDisplay(true);
          reset();
          toast.success("Otp Sent Successfully");
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          err.data && err.data.message && toast.error(err.data.message);
          console.log(err);
        });
    }
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsLoading(true);
    loginViaEmailAndOTP(otpFieldDisplay ? { ...data, otp: OTP } : data);
  };

  const loginToGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => setGoogleUser(codeResponse),
    onError: (error) => console.log("Login Failed:", error),
  });

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <div
        className="flex items-center justify-center bg-gradient-to-b from-blue-300 to-blue-100"
        style={{
          backgroundImage: `url(${bannerBg})`,
          width: "100%",
          height: "100vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="bg-white w-full max-w-md p-8 rounded-3xl shadow-xl text-center">
          <div className="flex justify-center mb-4">
            <div className="bg-gray-200 p-3 rounded-full">
              <span className="text-2xl">🔑</span>
            </div>
          </div>
          <h2 className="text-xl font-bold mb-2">
            {otpFieldDisplay ? "Enter OTP" : "Sign in with email"}
          </h2>
          <p className="text-gray-500 text-sm mb-6">
          Discover, Connect, and Collaborate Seamlessly
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            {otpFieldDisplay ? (
              <div className="mb-6 flex justify-center">
                <OtpInput
                  value={OTP}
                  onChange={handleChange}
                  numInputs={OTP_LENGTH}
                  inputType="tel"
                  name="otp"
                  renderSeparator={<span></span>}
                  inputStyle="inputStyle"
                  shouldAutoFocus={true}
                  renderInput={(props) => <input {...props} required />}
                />
              </div>
            ) : (
              <div className="relative mb-6">
                <InputField
                  fieldName="email"
                  register={register}
                  errors={errors}
                  validationObj={{
                    required: "Please Enter Email",
                    validate: {
                      matchPattern: (e) =>
                        /^([0-9]{9})|([A-Za-z0-9._%-]+@[a-z0-9.-]+\.[a-z]{2,3})$/.test(
                          e
                        ) || "Please Enter Valid Email",
                    },
                  }}
                  fieldType="text"
                  className="min-h-[auto] w-full rounded-xl border-1 bg-light py-[0.32rem] px-3 leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none"
                  placeholder="Please Enter Email"
                />
              </div>
            )}

            <div className="w-full flex flex-col items-center justify-center pb-3">
              <button
                type="submit"
                className="w-full max-w-sm mb-3 inline-block rounded-3xl bg-blue-900 px-7 pt-3 pb-2.5 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-blue-900 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)]"
                data-te-ripple-init
                data-te-ripple-color="light"
                onClick={(e) => handleSubmit(onSubmit, e)}
              >
                {otpFieldDisplay ? "Verify OTP" : "Sign in"}
              </button>
            </div>
          </form>
          <div className="flex items-center my-4">
            <hr className="flex-grow border-gray-300" />
            <span className="px-2 text-gray-400 text-sm">Or sign in with</span>
            <hr className="flex-grow border-gray-300" />
          </div>
          <div className="flex justify-center space-x-4">
            <button
              className="p-3 rounded-full bg-gray-100 hover:bg-gray-200"
              onClick={() => loginToGoogle()}
            >
              <FaGoogle className="text-red-500" />
            </button>
            <button className="p-3 rounded-full bg-gray-100 hover:bg-gray-200">
              <FaFacebook className="text-blue-600" />
            </button>
            <button className="p-3 rounded-full bg-gray-100 hover:bg-gray-200">
              <FaApple className="text-black" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
