import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { GET_COMPANY_DETAILS } from "../../apiConst";
import { postRequest } from "../interceptor/interceptor";
import FullScreenLoader from "../Loader/FullScreenLoader";
import { Button, Card } from "@mui/material";
import newsLetter from "../../images/newsLetter.jpg";
const SingleCompanyDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [companyData, setCompanyData] = useState(null);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const getCompanyData = async () => {
      setIsLoading(true);
      const obj = { companyId: params.collectionDetailId };
      const res = await postRequest(`${GET_COMPANY_DETAILS}/search`, obj);
      if (res?.data?.data?.length > 0) {
        setCompanyData(res.data.data[0]);
      }
      setIsLoading(false);
    };
    getCompanyData();
  }, [params.collectionDetailId]);
  const backToTable = () => {
    navigate(-1);
  };
  return (
    <>
      {isLoading && <FullScreenLoader />}
      {companyData && (
        <div className="font-montsettat flex justify-center px-4 py-2 lg:px-10">
          <Card
            elevation={2}
            className="w-full bg-gray-50 overflow-hidden"
          >
            {/* Header */}
            <div className="flex flex-col md:flex-row items-center md:items-start border-b pb-4 p-8">
              <img
                src={companyData?.img[0] || newsLetter}
                alt="Company Logo"
                className="h-20 w-20 rounded-full border-2 border-gray-300 object-cover"
              />
              <div className="ml-4 text-center md:text-left">
                <h1 className="text-2xl font-bold text-[#000000]">
                  {companyData?.title}
                </h1>
                <p className="text-gray-600 text-sm mt-1">
                  {companyData?.address}, {companyData?.city},{" "}
                  {companyData?.state} {companyData?.postalCode},{" "}
                  {companyData?.country}
                </p>
              </div>
            </div>

            {/* Tabs */}
            <div className="bg-gray-200 p-4 flex space-x-4 text-sm flex justify-center items-center">
              <span className="border-b-2 border-blue-900 pb-2 text-blue-900 font-semibold">
                Details & Media
              </span>
            </div>

            {/* Content Section */}
            <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
              {/* Photos Section */}
              <div className="col-span-1">
                <h2 className="text-lg font-semibold text-[#000000]">Photo</h2>
                <img
                  src={companyData?.img[0] || newsLetter}
                  alt="Main"
                  className="mt-2 rounded-md shadow object-cover"
                />
              </div>

              {/* Details Section */}
              <div className="col-span-1">
                {/* About Section */}
                <div className="mb-6 border-b border-gray-300 pb-2">
                  <h2 className="text-lg font-semibold text-[#000000]">
                    About Us
                  </h2>
                  <p className="text-gray-600 mt-2 text-sm">
                    {companyData?.companyDetails?.aboutUs ||
                      "No description available"}
                  </p>
                </div>

                {/* Company Details */}
                <div>
                  <h2 className="text-lg font-semibold text-[#000000]">
                    Company Details
                  </h2>
                  <div className="grid grid-cols-1 gap-4 mt-2">
                    <div className="p-3 border-b border-gray-300 pb-2">
                      <span className="font-semibold text-[#000000]">
                        Website:
                      </span>
                      <a
                        href={companyData?.companyDetails?.website}
                        target="__blank"
                        rel="noopener noreferrer"
                        className="text-blue-900 text-sm ml-2 font-semibold"
                      >
                        {companyData?.companyDetails?.website || "N/A"}
                      </a>
                    </div>
                    <div className="p-3 border-b border-gray-300 pb-2">
                      <span className="font-semibold text-[#000000]">
                        Worksite Address:
                      </span>
                      <span className="text-gray-600 text-sm ml-2">
                        {companyData?.worksiteAddress || "N/A"}
                      </span>
                    </div>
                    <div className="p-3 border-b border-gray-300 pb-2">
                      <span className="font-semibold text-[#000000]">
                        Employer Address:
                      </span>
                      <span className="text-gray-600 text-sm ml-2">
                        {companyData?.employerAddress || "N/A"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="m-5">
                  <Button
                    onClick={backToTable}
                    variant="contained"
                    color="info"
                  >
                    Back
                  </Button>
                </div>
              </div>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default SingleCompanyDetails;
