import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { HiMenuAlt3, HiX } from "react-icons/hi";
import { MdMenu } from "react-icons/md";
import { Button } from "@mui/material";

function Navbar() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (localStorage.getItem("userAuthToken")) {
      setIsLogin(true);
    }
  }, [params]);

  const handleLogout = () => {
    localStorage.removeItem("userAuthToken");
    localStorage.removeItem("locationIp");
    setIsLogin(false);
    setShowMobileNav(false);
    navigate("/login");
  };

  const handleLogin = () => {
    navigate("/login");
    setShowMobileNav(false);
  };
  let timeoutId;

  const navLinks = (
    <>
      <NavLink
        to="/"
        className="nav-item"
        onClick={() => setShowMobileNav(false)}
      >
        Home
      </NavLink>
      <NavLink
        to="/dashboard"
        className="nav-item"
        onClick={() => setShowMobileNav(false)}
      >
        Dashboard
      </NavLink>
      <NavLink
        to="/e/company"
        className="nav-item"
        onClick={() => setShowMobileNav(false)}
      >
        Geo Search
      </NavLink>
      <div
      className="relative group"
      onMouseEnter={() => {
        clearTimeout(timeoutId);
        setShowDropdown(true);
      }}
      onMouseLeave={() => {
        timeoutId = setTimeout(() => setShowDropdown(false), 200); // Delay hiding
      }}
    >
      <button className="flex items-center space-x-1 nav-item hover:bg-transparent transition duration-200">
        <span>Tools</span>
        {showDropdown ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
      </button>

      {/* Dropdown */}
      <div
        className={`absolute bg-blueBg text-white mt-2 rounded shadow-lg p-2 z-50 w-64 transition-opacity duration-200 ${
          showDropdown ? "opacity-100 scale-100" : "opacity-0 scale-95 pointer-events-none"
        }`}
      >
        <NavLink
          to="/margin-breakdown"
          onClick={() => {
            setShowDropdown(false);
            setShowMobileNav(false);
          }}
          className="block text-sm px-4 py-2 tracking-[0.2em] transition duration-200"
        >
          Margin Breakdown
        </NavLink>
      </div>
    </div>
      <a
        href="mailto:contact@itpros.tech"
        className="nav-item"
        onClick={() => setShowMobileNav(false)}
      >
        contact@itpros.tech
      </a>
      <div>
        {isLogin ? (
          <Button
            color="error"
            variant="contained"
            onClick={handleLogout}
            className="tracking=[0.2em]"
          >
            Logout
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={handleLogin}
            className="tracking=[0.2em]"
          >
            Login
          </Button>
        )}
      </div>
    </>
  );

  return (
    <nav className="bg-blueBg text-white sticky top-0 z-[998] shadow-xl py-5 px-3 lg:px-10">
      <div className="flex justify-between items-center">
        <Link
          to="/"
          className="text-xl font-bold tracking-[0.2em] font-montserrat"
        >
          ITPROS.TECH
        </Link>

        {/* Desktop Nav */}
        <div className="hidden lg:flex gap-6 items-center">{navLinks}</div>

        {/* Mobile Toggle Button */}
        <div className="lg:hidden">
          <button onClick={() => setShowMobileNav(!showMobileNav)}>
            {showMobileNav ? (
              <HiX size={28} className="text-white" />
            ) : (
              <MdMenu size={28} className="text-white" />
            )}
          </button>
        </div>
      </div>

      {/* Mobile Nav */}
      {showMobileNav && (
        <div className="lg:hidden px-6 py-4 space-y-6 flex flex-col items-start animate-slide-down">
          {navLinks}
        </div>
      )}
    </nav>
  );
}

export default Navbar;
