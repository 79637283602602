import React from "react";
import { PieChart, Pie, Cell, Tooltip, Legend ,ResponsiveContainer,} from "recharts";

const Circularchart = ({ticketData,requestCircularMode}) => {
  console.log("ticketData", ticketData);
  console.log("requestCircularMode", requestCircularMode);

  // Function to count ticket types based on requestCircularMode
  const countTicketsByStatus = (tickets = [], status) => {
    return tickets.filter(
      (ticket) => ticket.requestMode === requestCircularMode
    ).length;
  };

  const newTickets = countTicketsByStatus(ticketData.newTicket, "new");
  const inProgress = countTicketsByStatus(ticketData.inprogress, "inprogress");
  const rejected = countTicketsByStatus(ticketData.rejected, "rejected");
  const resolved = countTicketsByStatus(ticketData.resolved, "resolved");

  const data = [
    { name: "New", value: newTickets, color: "#2f4b7c" },
    { name: "In-Progress", value: inProgress, color: "#f28927" },
    { name: "Rejected", value: rejected, color: "#c44627" },
    { name: "Resolved", value: resolved, color: "#5D883C" },
  ];
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 rounded-lg shadow-lg border border-gray-300 text-gray-800">
          <p className="font-semibold text-black">{payload[0].name}</p>
          <p className="text-sm">
            Tickets:{" "}
            <span className="font-bold text-blue-600">{payload[0].value}</span>
          </p>
        </div>
      );
    }
    return null;
  };
  const CustomLegend = ({ payload }) => {
    return (
      <div className="flex flex-wrap justify-center gap-4 pt-4 text-sm text-gray-700">
        {payload?.map((entry, index) => (
          <div key={`legend-${index}`} className="flex items-center space-x-2">
            <div
              className="w-3 h-3 rounded-lg"
              style={{ backgroundColor: entry.color }}
            />
            <span>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-6">
      <div className="w-full h-[300px]">
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={100}
              dataKey="value"
              label={({ percent }) => `${(percent * 100).toFixed(1)}%`}
              isAnimationActive={true}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip
              content={<CustomTooltip />}
              cursor={{ fill: "rgba(0, 0, 0, 0.1)" }}
            />
            <Legend content={<CustomLegend />} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Circularchart;
