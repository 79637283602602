import React, { useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { FaRegCalendarAlt } from "react-icons/fa"; // calendar icon

const DateRangePicker = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const [includeToday, setIncludeToday] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleApply = () => {
    if (includeToday) {
      setEndDate(new Date());
    }
    console.log("Start:", startDate, "End:", endDate);
    setIsOpen(false);
  };

  const formatDisplayDate = (date) => moment(date).format("DD MMM YYYY");
  const placeholderText = `${formatDisplayDate(
    startDate || new Date()
  )} - ${formatDisplayDate(endDate || new Date())}`;

  return (
    <div className=" w-full z-[999]">
      {/* Toggle Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full flex items-center bg-white hover:bg-white hover:text-gray-800 justify-between gap-2 px-4 py-1.5 rounded-md shadow-sm hover:shadow-md transition duration-150 text-sm text-gray-800"
      >
        <span className="truncate">{placeholderText}</span>
        <FaRegCalendarAlt className="text-gray-500" />
      </button>

      {/* Calendar Panel */}
      {isOpen && (
        <div className="absolute mt-2 rounded shadow-2xl bg-white z-50" onMouseLeave={()=>setIsOpen(false)}>
          {/* Header */}
          <div className="flex items-center justify-between px-4 py-2 bg-[#006183] text-white text-sm">
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                className="form-checkbox accent-white rounded"
                checked={includeToday}
                onChange={() => setIncludeToday(!includeToday)}
              />
              Include today
            </label>
            <span className="text-sm">Last 30 days</span>
          </div>

          {/* Calendars */}
          <div className="flex flex-col md:flex-row md:justify-between gap-2 px-4 pt-3">
            {/* Start */}
            <div>
              <div className="text-sm font-medium mb-1 text-gray-700">
                Start date
              </div>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                inline
                calendarClassName="!text-sm"
              />
            </div>

            {/* End */}
            <div>
              <div className="text-sm font-medium mb-1 text-gray-700">
                End date
              </div>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                inline
                calendarClassName="!text-sm"
              />
            </div>
          </div>

          {/* Footer */}
          {/* <div className="flex justify-end px-4 py-3 border-t border-gray-200 text-sm">
            <button
              onClick={() => setIsOpen(false)}
              className="mr-4 text-gray-700 hover:bg-transparent hover:text-gray-700 "
            >
              CANCEL
            </button>
            <button
              onClick={handleApply}
              className="px-5 py-1 rounded bg-[#006183] text-white font-medium shadow hover:bg-[#006183]"
            >
              APPLY
            </button>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
