import React from 'react'
import HeroSection from '../hero/HeroSection'
import FeaturesSection from '../features/FeaturesSection'
import GallerySection from '../gallery/GallerySection'
import StatsSection from '../stats/StatsSection'
import { AboutUs } from '../aboutUs/AboutUs'
import Footer from '../footer/Footer'
import BlogSection from '../blog/BlogSection'
import WorkProcessSection from '../workProcess/WorkProcessSection'
import NewsLetterSection from '../news/NewsLetterSection'
import TestimonialSection from '../testimonials/TestimonialSection'
import HeroSearch from '../HeroSearch'
import MainSection from '../MainSection'
import ContactUs from '../contact/ContactUs'


const Home = () => {
  return (
    <>
    <HeroSearch/>
    {/* <MainSection/> */}
    {/* <HeroSection/>
    <AboutUs/>
    <FeaturesSection/>
    <NewsLetterSection/>
    <GallerySection/>
    <WorkProcessSection/> */}
    {/* <TestimonialSection/> */}
    <BlogSection/>
    {/* <StatsSection/>  */}
    <ContactUs/>
    
    </>
  )
}

export default Home