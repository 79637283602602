import React from "react";
import { FaEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

function TableCard({
  onEdit,
  onView,
  onDelete,
  status,
  ticketNo,
  requestTitle,
  referralName,
  applicantName,
  requestDescription,
  requestType,
  issueOpen,
  ticketCreatedBy,
  requestNumber,
  requestCreatedBy,
  requestedAmount,
  requestedDate,
  grantedAmount,
  grantedDate,
  newsId,
  title,
  description,
  link,
  allowedRoles = [],
  role,
  userRole,
  id,
  firstName,
  lastName,
  email,
  contactNo,
  type,
  videoId,
  videoTitle,
  videoLink,
  videoType,
  photo,
}) {
  // Check if the current role is allowed to perform actions
  const isAllowed = allowedRoles.includes(role);

  return (
    <div className="rounded-2xl flex items-center justify-between mt-2 mb-2 bg-white p-6 shadow-lg border border-gray-200">
      <div>
        {ticketNo && (
          <p
            className={`text-lg font-semibold underline ${
              isAllowed
                ? "text-blue-900 cursor-pointer hover:font-bold"
                : "text-gray-500 cursor-default"
            }`}
            onClick={isAllowed ? onView : undefined}
          >
            <span className="font-bold">Ticket Number:</span> {ticketNo}
          </p>
        )}
        {requestTitle && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Request Title:</span>{" "}
            {requestTitle}
          </p>
        )}
        {requestNumber && (
          <p
            className={`text-lg font-semibold underline ${
              isAllowed
                ? "text-blue-900 cursor-pointer hover:font-bold"
                : "text-gray-500 cursor-default"
            }`}
            onClick={isAllowed ? onView : undefined}
          >
            <span className="font-bold">Request Number:</span> {requestNumber}
          </p>
        )}

        {requestCreatedBy && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Requested Created by:</span>{" "}
            {requestCreatedBy}
          </p>
        )}
        {requestedAmount && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Requested Amount:</span>{" "}
            {requestedAmount}
          </p>
        )}
        {requestedDate && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Requested Date:</span> {requestedDate}
          </p>
        )}
        {grantedAmount && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Granted Amount:</span> {grantedAmount}
          </p>
        )}
        {grantedDate && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Granted Date:</span> {grantedDate}
          </p>
        )}

        {/* News Section */}
        {newsId && (
          <p
            className="text-lg font-semibold text-blue-900 underline cursor-pointer"
            onClick={onView}
          >
            <span className="font-bold">News Id:</span> {newsId}
          </p>
        )}
        {title && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Title:</span> {title}
          </p>
        )}
        {type && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Type:</span> {type}
          </p>
        )}
        {description && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Description:</span> {description}
          </p>
        )}
        {link && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Link:</span> {link}
          </p>
        )}

        

        {applicantName && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Applicant Name:</span> {applicantName}
          </p>
        )}

        {requestDescription && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Request Description:</span>{" "}
            {requestDescription}
          </p>
        )}
        {requestType && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Request Type:</span> {requestType}
          </p>
        )}
        
        {referralName && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Referral Name:</span> {referralName}
          </p>
        )}
        {issueOpen && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Issue Open Date:</span> {issueOpen}
          </p>
        )}
        {ticketCreatedBy && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Ticket Created By:</span>{" "}
            {ticketCreatedBy}
          </p>
        )}

        {id && (
          <p className="text-md text-gray-800">
            <span className="font-bold">User ID:</span> {id}
          </p>
        )}
        {firstName && (
          <p className="text-md text-gray-800">
            <span className="font-bold">First Name:</span> {firstName}
          </p>
        )}
        {lastName && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Last Name:</span> {lastName}
          </p>
        )}
        {email && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Email:</span> {email}
          </p>
        )}
        {userRole && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Role</span> {userRole}
          </p>
        )}
        {contactNo && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Contact:</span> {contactNo}
          </p>
        )}

        {videoId && (
          <p
            className={`text-lg font-semibold underline ${
              isAllowed
                ? "text-blue-900 cursor-pointer hover:font-bold"
                : "text-gray-500 cursor-default"
            }`}
            onClick={isAllowed ? onEdit : undefined}
          >
            <span className="font-bold">Video Id:</span> {videoId}
          </p>
        )}
        {videoTitle && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Video Title:</span> {videoTitle}
          </p>
        )}
        {videoLink && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Video Link:</span> {videoLink}
          </p>
        )}

        {videoType && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Video Type:</span> {videoType}
          </p>
        )}
        {status && (
          <p className="text-md text-gray-800">
            <span className="font-bold">Status:</span> {status}
          </p>
        )}
        {photo && (
          <a href={photo} target="_blank" rel="noopener noreferrer">
            <img
              src={photo}
              alt="Uploaded file preview"
              className="w-32 h-32 object-cover rounded-md border border-gray-300"
            />
          </a>
        )}
      </div>

      {/* Action Buttons */}

      <div className="flex ml-4 justify-end space-x-4">
        {onEdit && isAllowed && (
          <div
            onClick={onEdit}
            className="cursor-pointer text-blue-600 hover:text-blue-800 transition"
          >
            <FaEdit size={25} />
          </div>
        )}
        {onView && isAllowed && (
          <div
            onClick={onView}
            className="cursor-pointer text-blue-600 hover:text-blue-800 transition"
          >
            <FaEye size={25} />
          </div>
        )}
        {onDelete && isAllowed && (
          <div
            onClick={onDelete}
            className="cursor-pointer text-red-600 hover:text-red-800 transition"
          >
            <MdDelete size={25} />
          </div>
        )}
      </div>
    </div>
  );
}

export default TableCard;
