export const requestFields = [
  {
    fieldName: 'requestTitle',
    fieldTitle: 'Request Title*',
    // validationObj: {
    //   required: 'Please Enter Request Title',
    // },
    fieldType: 'text', // Could be 'disabledInput' if auto-populated
  },
];

export const dataEntryGrievanceTicketFields = [
  {
    fieldName: 'ticketNo',
    fieldTitle: 'Request Number*',
    // validationObj: {
    //   required: 'Please Enter Reference Number',
    // },

    fieldType: 'text',
  },

  {
    fieldName: 'ticketCreatedBy',
    fieldTitle: 'Request Created by',
    fieldType: 'text', // Could be 'disabledInput' if auto-populated
    readOnly: true, // Potentially make it read-only
  },

  {
    fieldName: 'staffDesignation',
    fieldTitle: 'Staff Designation',
    fieldType: 'text', // Could be 'disabledInput' if auto-populated
    readOnly: true,
  },
  {
    fieldName: 'issueOpen',
    fieldTitle: 'Request Open Date',
    fieldType: 'date',
  },
];

export const grievanceApplicantFields = [
  {
    fieldName: 'applicantName',
    fieldTitle: 'Applicant Name*',
    // validationObj: {
    //   required: 'Please Enter Applicant Name',
    // },
    fieldType: 'text',
  },
  {
    fieldName: 'applicantMobile',
    fieldTitle: 'Applicant Contact*',
    // validationObj: {
    //   required: 'Please Enter Applicant Mobile Phone',
    // },
    fieldType: 'text', // Consider adding input masking for phone number format
  },
  {
    fieldName: 'pincode',
    fieldTitle: 'Pincode',
    // validationObj: {
    //   required: 'Please Enter Pincode',
    // },
    fieldType: 'number',
  },
  {
    fieldName: 'state',
    fieldTitle: 'State*',
    // validationObj: {
    //   required: 'Please Select State',
    // },
    fieldType: 'select',
    options: [], // You'll need to populate this with state options
  },
  {
    fieldName: 'district',
    fieldTitle: 'District*',
    // validationObj: {
    //   required: 'Please Select District',
    // },
    fieldType: 'select',
    options: [], // District options will depend on the selected state
  },
  {
    fieldName: 'constituency',
    fieldTitle: 'Constituency*',
    // validationObj: {
    //   required: 'Please Enter Constituency',
    // },
    fieldType: 'text',
  },
  {
    fieldName: 'mandal',
    fieldTitle: 'Mandal*',
    // validationObj: {
    //   required: 'Please Enter Mandal',
    // },
    fieldType: 'select',
    options: [],
  },
  {
    fieldName: 'village',
    fieldTitle: 'Village',
    fieldType: 'select',
    options: [], // Or 'select' if you have a predefined list of villages
  },
];
export const grievanceRequestFields = [
  // {
  //   fieldName: 'requestDescription',
  //   fieldTitle: 'Request Description',
  //   validationObj: {
  //     required: 'Please Enter Request Description',
  //   },
  //   fieldType: 'textarea',
  // },
  {
    fieldName: 'requestMode',
    fieldTitle: 'Request Mode*',
    fieldType: 'select',
    options: [
      { label: 'State', value: 'state' },
      { label: 'Central', value: 'central' },
      { label: 'Private', value: 'private' },
    ],
  },
  

  {
    fieldName: 'additionalInformation',
    fieldTitle: 'Additional Information of Request',
    fieldType: 'textarea',
  },
];

export const referalFields = [
  {
    fieldName: 'referralType',
    fieldTitle: 'Referral Type*',
    // validationObj: {
    //   required: 'Please Select Referral Type',
    // },
    fieldType: 'select',
    options: [], // You'll need to populate this with state options
  },
  {
    fieldName: 'referralMode',
    fieldTitle: 'Referral Mode*',
    // validationObj: {
    //   required: 'Please Select Referred Mode',
    // },
    fieldType: 'select',
    options: [], // You'll need to populate this with state options
  },
  {
    fieldName: 'referralContact',
    fieldTitle: 'Referral Contact',
    fieldType: 'text', // Or 'select' if you have a predefined list of villages
  },

  {
    fieldName: 'referralName',
    fieldTitle: 'Referral Name',
    fieldType: 'text', // Or 'select' if you have a predefined list of villages
  },

  {
    fieldName: 'referralDesignation',
    fieldTitle: 'Referral Designation',
    fieldType: 'text', // Or 'select' if you have a predefined list of villages
  },
];
export const followUpFields = [
  {
    fieldName: 'followUpEmail',
    fieldTitle: 'Email*',
    validationObj: {
      required: 'Please Enter Email Address',
    },
    fieldType: 'text',
  },
  {
    fieldName: 'followUpDate',
    fieldTitle: 'Date*',
    fieldType: 'date',
  },
  {
    fieldName: 'followUpNote',
    fieldTitle: 'Note*',
    fieldType: 'textarea',
  },
];

export const approvalActionFields = [
  {
    fieldName: 'priority',
    fieldTitle: 'Priority*',
    // validationObj: {
    //   required: 'Please Select Priority:',
    // },
    fieldType: 'select',
    options: [],
  },
  {
    fieldName: 'assignedTo',
    fieldTitle: 'Assigned To*',
    // validationObj: {
    //   required: 'Please Select Assigned To:',
    // },
    fieldType: 'select',
    options: [], // You'll need to populate this with state options
  },
  {
    fieldName: 'targetResolution',
    fieldTitle: 'Target Resolution',
    fieldType: 'date',
  },
];

export const applicantDetailsFields = [
  {
    fieldName: 'applicantName',
    fieldTitle: 'Applicant Name*',
    // validationObj: {
    //   required: 'Please Enter Applicant Name',
    // },
    fieldType: 'text',
  },
  {
    fieldName: 'applicantMobile',
    fieldTitle: 'Applicant Contact*',
    // validationObj: {
    //   required: 'Please Enter Applicant Mobile Phone',
    // },
    fieldType: 'text', // Consider adding input masking for phone number format
  },
  {
    fieldName: 'pincode',
    fieldTitle: 'Pincode',
    // validationObj: {
    //   required: 'Please Enter Pincode',
    // },
    fieldType: 'text',
  },
  {
    fieldName: 'state',
    fieldTitle: 'State*',
    // validationObj: {
    //   required: 'Please Select State',
    // },
    fieldType: 'select',
    options: [], // You'll need to populate this with state options
  },
  {
    fieldName: 'district',
    fieldTitle: 'District*',
    // validationObj: {
    //   required: 'Please Select District',
    // },
    fieldType: 'select',
    options: [], // District options will depend on the selected state
  },
  {
    fieldName: 'constituency',
    fieldTitle: 'Constituency*',
    // validationObj: {
    //   required: 'Please Enter Constituency',
    // },
    fieldType: 'text',
  },
  {
    fieldName: 'mandal',
    fieldTitle: 'Mandal*',
    // validationObj: {
    //   required: 'Please Enter Mandal',
    // },
    fieldType: 'select',
    options: [],
  },
  {
    fieldName: 'village',
    fieldTitle: 'Village',
    fieldType: 'select',
    options: [], // Or 'select' if you have a predefined list of villages
  },
];

export const grievanceStepperFields = [
  {
    fieldName: 'ticketNo',
    fieldTitle: 'Request Number*',
    // validationObj: {
    //   required: 'Please Enter Reference Number',
    // },

    fieldType: 'text',
  },
  {
    fieldName: 'applicantName',
    fieldTitle: 'Applicant Name*',
    // validationObj: {
    //   required: 'Please Enter Applicant Name',
    // },
    fieldType: 'text',
  },
  {
    fieldName: 'issueOpen',
    fieldTitle: 'Request Open Date',
    fieldType: 'date',
  },
  {
    fieldName: 'ticketCreatedBy',
    fieldTitle: 'Request Created by',
    fieldType: 'text', // Could be 'disabledInput' if auto-populated
    readOnly: true, // Potentially make it read-only
  },
  {
    fieldName: 'staffDesignation',
    fieldTitle: 'Staff Designation',
    fieldType: 'text', // Could be 'disabledInput' if auto-populated
    readOnly: true,
  },
  {
    fieldName: 'targetResolution',
    fieldTitle: 'Target Resolution',
    fieldType: 'date',
  },
  // {
  //   fieldName: 'requestDescription',
  //   fieldTitle: 'Request Description',
  //   validationObj: {
  //     required: 'Please Enter Request Description',
  //   },
  //   fieldType: 'textarea',
  // },
  {
    fieldName: 'requestMode',
    fieldTitle: 'Request Mode*',
    fieldType: 'select',
    options: [
      { label: 'State', value: 'state' },
      { label: 'Central', value: 'central' },
      { label: 'Private', value: 'private' },
    ],
  },
  

  {
    fieldName: 'requestDetails',
    fieldTitle: 'Request Details',
    fieldType: 'checkbox', // This seems like a checkbox based on the image
  },

  {
    fieldName: 'additionalInformation',
    fieldTitle: 'Additional Information of Request',
    fieldType: 'textarea',
  },

  {
    fieldName: 'referral',
    fieldTitle: 'Referral',
    fieldType: 'checkbox',
  },
];

export const grievanceStepperTicketFields = [
  {
    fieldName: 'ticketNo',
    fieldTitle: 'Request Number*',
    // validationObj: {
    //   required: 'Please Enter Reference Number',
    // },

    fieldType: 'text',
  },

  {
    fieldName: 'issueOpen',
    fieldTitle: 'Request Open Date',
    fieldType: 'date',
  },
  {
    fieldName: 'ticketCreatedBy',
    fieldTitle: 'Request Created by',
    fieldType: 'text', // Could be 'disabledInput' if auto-populated
    readOnly: true, // Potentially make it read-only
  },
  {
    fieldName: 'staffDesignation',
    fieldTitle: 'Staff Designation',
    fieldType: 'text', // Could be 'disabledInput' if auto-populated
    readOnly: true,
  },
];

export const grievanceTableHeader = [
  {
    id: 'sno',
    numeric: false,
    label: 'S.No',
    disabled: true,
  },
  {
    id: 'ticketNo',
    numeric: false,
    label: 'Request Number',
    disabled: true,
  },
  {
    id: 'requestTitle',
    numeric: false,
    label: 'Request Title',
    disabled: true,
  },
  {
    id: 'applicantName',
    numeric: false,
    label: 'Applicant Name',
    disabled: true,
  },

  {
    id: 'requestType',
    numeric: false,
    label: 'Request Type',
    disabled: true,
  },
  {
    id: 'referralName',
    numeric: false,
    label: 'Referral Name',
    disabled: true,
  },
  // {
  //   id: 'assignedTo',
  //   numeric: false,
  //   label: 'Assigned To',
  //   disabled: true,
  // },
  // {
  //   id: 'issueOpen',
  //   numeric: false,
  //   label: 'Reference Open Date',
  //   disabled: true,
  // },

  // {
  //   id: 'ticketCreatedBy',
  //   numeric: false,
  //   label: 'Created By',
  //   disabled: true,
  // },
  {
    id: 'ticketStatus',
    numeric: false,
    label: 'Status',
    disabled: true,
  },
  {
    id: 'action',
    numeric: false,
    label: 'Actions',
    disabled: true,
  },
];

export const dashboardTableHeader = [
  {
    id: 'sno',
    numeric: false,
    label: 'S.No',
    disabled: true,
  },
  {
    id: 'ticketNo',
    numeric: false,
    label: 'Request Number',
    disabled: true,
  },
  {
    id: 'requestTitle',
    numeric: false,
    label: 'Request Title',
    disabled: true,
  },
  {
    id: 'applicantName',
    numeric: false,
    label: 'Applicant Name',
    disabled: true,
  },

  {
    id: 'requestType',
    numeric: false,
    label: 'Request Type',
    disabled: true,
  },

  {
    id: 'referralName',
    numeric: false,
    label: 'Referral Name',
    disabled: true,
  },

  // {
  //   id: 'issueOpen',
  //   numeric: false,
  //   label: 'Reference Open Date',
  //   disabled: true,
  // },
  // {
  //   id: 'assignedTo',
  //   numeric: false,
  //   label: 'Assigned To',
  //   disabled: true,
  // },
  // {
  //   id: 'staffDesignation',
  //   numeric: false,
  //   label: 'Designation',
  //   disabled: true,
  // },
  {
    id: 'ticketStatus',
    numeric: false,
    label: 'Status',
    disabled: true,
  },
  {
    id: 'action',
    numeric: false,
    label: 'Actions',
    disabled: true,
  },
];

export const taskTableHeader = [
  {
    id: 'sno',
    numeric: false,
    label: 'S.No',
    disabled: true,
  },
  {
    id: 'taskId',
    numeric: false,
    label: 'Task Id',
    disabled: true,
  },
  {
    id: 'taskTitle',
    numeric: false,
    label: 'Task Title',
    disabled: true,
  },
  {
    id: 'taskDescription',
    numeric: false,
    label: 'Task Description',
    disabled: true,
  },
  {
    id: 'startDate',
    numeric: false,
    label: 'Start Date',
    disabled: true,
  },
  {
    id: 'targetDate',
    numeric: false,
    label: 'Target Date',
    disabled: true,
  },
  {
    id: 'actualHours',
    numeric: false,
    label: 'Actual Hours',
    disabled: true,
  },
  {
    id: 'estimatedHours',
    numeric: false,
    label: 'Estimated Hours',
    disabled: true,
  },
  {
    id: 'user.email',
    numeric: false,
    label: 'Assign To',
    disabled: true,
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    disabled: true,
  },
  {
    id: 'action',
    numeric: false,
    label: 'Actions',
    disabled: true,
  },
];
