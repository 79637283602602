import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { useNavigate } from "react-router";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Pagination } from "@mui/material";
import { FaAnglesDown } from "react-icons/fa6";
import newsLetter from "../../images/newsLetter.jpg";
const CompanyDisplay = ({ tableRowsData}) => {
  const navigate = useNavigate();
  const goToCompanyPage = (companyId) => {
    navigate(`/${companyId}`);
  };

  const itemsPerPage = 8;
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };

  const paginatedData = tableRowsData.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  return (
    <div className="w-full">
      <div className="grid gap-4 md:grid-cols-4 sm:grid-cols-2 grid-cols-1">
        {paginatedData.map((data, i) => (
          <ProfileCard data={data} goToCompanyPage={goToCompanyPage} key={i} />
        ))}
      </div>
      <div className="flex justify-center mt-6">
        <Pagination
          count={Math.ceil(tableRowsData.length / itemsPerPage)}
          page={page}
          onChange={handleChange}
          color="primary"
        />
      </div>
    </div>
  );
};

const ProfileCard = ({ data, goToCompanyPage }) => {
  console.log('data',data)
  return (
    <div className="p-4 bg-white border border-gray-300 rounded-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
      {/* Checkbox for Selection */}
      {/* <div className="absolute top-2 left-2">
        <input type="checkbox" className="w-5 h-5 cursor-pointer" />
      </div> */}

      {/* Image Container with Carousel Dots */}
      <div className="flex justify-center w-full overflow-hidden rounded-t-lg">
        <img
          src={data.img[0] || newsLetter}
          className="w-full h-56 object-cover"
          alt={data.title}
        />
        {/* Dots Indicator */}
        {/* <div className="absolute bottom-2 left-2 flex space-x-1">
          {[...Array(8)].map((_, i) => (
            <span
              key={i}
              className={`w-2 h-2 rounded-full ${
                i === 0 ? "bg-blue-600" : "bg-gray-400"
              }`}
            ></span>
          ))}
        </div> */}
        {/* More Images Count Badge */}
        {/* <div className="absolute bottom-2 right-2 bg-gray-800 text-white text-xs px-2 py-1 rounded-full">
          +{data.img.length - 1}
        </div> */}
      </div>

      {/* Content Section */}
      <div className="p-3 text-center">
        <h5 className="text-md font-semibold text-gray-900 truncate">
          {data.title}
        </h5>
        <p className="text-sm text-gray-500">{data?.country}</p>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-center px-2 pb-2 my-auto">
        <button
          className="flex items-center bg-blue-600 text-white text-sm px-4 py-2 rounded-md shadow-md hover:bg-blue-700 transition-all"
          onClick={() => goToCompanyPage(data.companyId)}
        >
          <FaAnglesDown  className="mr-2" /> View Details
        </button>
      </div>
    </div>
  );
};

export default CompanyDisplay;
