import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function NavigationTabs({ heading, navTabs, tab_1, tab_2, tab_3, tab_4 }) {
  const [toggleState, setToggleState] = useState(1);
  const navigate = useNavigate();

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <div className="block overflow-y-auto">
      <div className="flex text-[8px] text-gray-800 md:text-sm mb-3">
        {navTabs &&
          navTabs.map((tab) => (
            <button
              key={tab.index}
              className={
                toggleState === tab.index ? "tabs active-tabs" : "tabs"
              }
              onClick={() => toggleTab(tab.index)}
            >
              {tab.title}
            </button>
          ))}
      </div>

      <div
        className={toggleState === 1 ? "active-content" : "content"}
      >
        {tab_1}
      </div>
      <div
        className={toggleState === 2 ? "active-content" : "content"}
      >
        {tab_2}
      </div>
      <div
        className={toggleState === 3 ? "active-content" : "content"}
      >
        {tab_3}
      </div>
      <div
        className={toggleState === 4 ? "active-content" : "content"}
      >
        {tab_4}
      </div>
    </div>
  );
}

export default NavigationTabs;
