import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import App from "./App";
import CollectionSearch from "./Components/CollectionSearch";
import CompanyTable from "./Components/CompanyDetails";
import SingleCompanyDetails from "./Components/CompanyDetails/SingleCompanyDetails";
import ExcelFileUpload from "./Components/ExcelFileUpload";
import FileDataDisplay from "./Components/ExcelFileUpload/FileDataDisplay";
import FormBuilder from "./Components/FormBuilder";
import FormBuilderTable from "./Components/FormBuilder/FormBuilderTable";
import FormBuilderDataDisplay from "./Components/FormBuilderDataDisplay";
import FormBuilderStartPage from "./Components/FormBuilderDataDisplay/Start";
import Login from "./Components/Login";
import ProxyApiTable from "./Components/ProxyApi";
import ProxyForm from "./Components/ProxyApi/Form";
import ProtectedRoute from "./protectedRoutes";
import MarginBreakdown from "./Components/marginBreakdown/MarginBreakdown";
import AddMargin from "./Components/marginBreakdown/AddMargin";
import FormBuilderCardDisplay from "./Components/FormBuilder/FormBuilderCardDisplay";
import Navbar from "./Components/Navbar/navbar";
import Home from "./Components/home/Home";
import SingleBlogView from "./Components/blog/SingleBlogView";
import Dashboard from "./Components/Dashboard/Dashboard";
const Routing = (props) => {
  const allRoutes = (props) => {
    return (
      <>
     {/* <BrowserRouter> */}
        <Routes>
          <Route
            path="/form-builder"
            element={
              <ProtectedRoute>
                <FormBuilderTable />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-margin"
            element={
              <ProtectedRoute>
                <AddMargin />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/margin-breakdown"
            element={
              <ProtectedRoute>
                <MarginBreakdown />
              </ProtectedRoute>
            }
          />
          <Route
            path="/add-form"
            element={
              <ProtectedRoute>
                <FormBuilder />
              </ProtectedRoute>
            }
          />
          <Route
            path="/data"
            element={
              <ProtectedRoute>
                <ExcelFileUpload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/proxy"
            element={
              <ProtectedRoute>
                <ProxyApiTable />
              </ProtectedRoute>
            }
          />

          <Route
            path="/add-proxy"
            element={
              <ProtectedRoute>
                <ProxyForm />
              </ProtectedRoute>
            }
          />

          <Route
            path="/data/:fileId"
            element={
              <ProtectedRoute>
                <FileDataDisplay />
              </ProtectedRoute>
            }
          />
          <Route path="/home" element={<App />} />
          <Route
            path="/form-builder-data/:formUniqueId"
            element={<FormBuilderStartPage />}
          />
          <Route
            path="/form-builder-data/:formUniqueId/:dataInsertedId"
            element={<FormBuilderDataDisplay />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/search" element={<CompanyTable />} />
          <Route path="/e/:collectionId" element={<CollectionSearch />} />
          <Route
            path="/:collectionDetailId"
            element={<SingleCompanyDetails />}
          />
          <Route path="/forms" element={<FormBuilderCardDisplay />} />
          <Route path="/" element={<Home />} />
          <Route path="/viewMore" element={<SingleBlogView />} />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        {/* </BrowserRouter> */}
      </>
    );
  };

  return <>{allRoutes(props)}</>;
};

// const select = (store) => store;

export default Routing;
