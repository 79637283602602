import React from "react";
import { IoMdPerson } from "react-icons/io";
import { useLocation, useNavigate } from "react-router";

const SingleBlogView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const blog = location.state.blog;

  const handleBack = () => {
    navigate('/'); // Navigate to the previous page
  };

  return (
    <div className="min-h-screen bg-blue-50 flex flex-col">
      {/* <div className="relative w-full bg-gray-800 text-white p-4">
        <button
          onClick={handleBack}
          className="absolute top-4 left-4 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md text-sm"
        >
          Back
        </button>
        <h1 className="text-center text-3xl font-bold">Blog Details</h1>
      </div> */}

      <div className="py-2 px-4 lg:px-10">
        <div className="bg-white rounded-lg shadow-md overflow-hidden">
          {/* Blog Image */}
          <div className="relative">
            <img
              src={blog.image}
              alt={`Blog ${blog.id}`}
              className="w-full h-96 object-cover"
            />
            <div className="absolute top-4 right-4 bg-blue-600 text-white px-4 py-1 rounded text-sm font-medium">
              {blog.date}
            </div>
          </div>

          {/* Blog Content */}
          <div className="p-6">
            <div className="flex items-center text-sm text-gray-600 mb-4">
              <span className="flex items-center mr-4 text-xs">
                <IoMdPerson className="mr-1 text-blue-600" /> By {blog.author}
              </span>
            </div>

            <h1 className="text-3xl font-bold text-gray-900 mb-6">
              {blog.title}
            </h1>
            <p className="text-base text-gray-700 leading-relaxed mb-6">
              {blog.description}
            </p>

            <div className="text-base text-gray-700 leading-relaxed">
              {blog.content}
            </div>
          </div>
          <div className="flex justify-center items-center p-3">
          <button
          onClick={handleBack}
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md text-sm"
        >
          Back
        </button>
          </div>
         
        </div>
        
      </div>
      
    </div>
  );
};

export default SingleBlogView;