import React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "../../css/table.css";
import { get } from "lodash";
import { getComparator, stableSort, TableComponent } from "../../Common/Table";
import { useState } from "react";
import { IoEye } from "react-icons/io5";
import { Tooltip } from "@mui/material";
import SelectDropdown from "../../Common/SelectDropdown";
import {
    dashboardTableHeader,
    grievanceTableHeader,
  } from "../../fields/grievanceFields.js";
import { useMediaQuery } from "react-responsive";
import TableCard from "../../Common/TableCard";
import { useSelector } from "react-redux";
export default function CompaniessList({
  heading,
  tableRowsData,
  getListData,
  rowsPerPage,
  setRowsPerPage,
  totalCount,
  page,
  setPage,
  onView,
  onStepperForm,
  register,
  errors,
}) {
  const isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("id");
  const [searchVal, setSearchVal] = useState("");
  const headers = dashboardTableHeader;
  const  role  = useSelector((state) => state?.user?.role);

  const handleChangePage = (event, newPage) => {
    console.log("new page: " + newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to first page
  };

  const handleSearchFieldDropdown = (e) => {
    if (searchVal) {
      getListData(1, rowsPerPage, searchVal, e.target.value);
    }
  };

  const componentDisplay = () => {
    const cells = headers?.filter(
      (cell) =>
        cell.id === "requestType" ||
        cell.id === "assignedTo" ||
        cell.id === "referralName"
    );
    return (
      <div className="lg:mr-3 lg:w-52 text-center mb-[-15px] w-96">
        {headers.length > 0 && (
          <SelectDropdown
            fieldName="searchField"
            options={cells}
            optionsLabelKey="label"
            optionsValueKey="id"
            register={register}
            onChange={handleSearchFieldDropdown}
            errors={errors}
            fieldType="select"
            className="min-h-[auto] ml-3 w-48 border-1 bg-light py-2 text-sm text-gray-900 bg-white-900 rounded-md pl-2 focus:outline-none focus:bg-white focus:text-gray-900"
            emptyOption={false}
          />
        )}
      </div>
    );
  };
  const statusStyles = {
    new: {
      text: "#2f4b7c",
      bg: "#e0e6f5", // light shade of #2f4b7c
    },
    inprogress: {
      text: "#f28927",
      bg: "#fde9d1", // light shade of #f28927
    },
    OnHold: {
      text: "#f26033",
      bg: "#fbded7", // light shade of #f26033
    },
    resolved: {
      text: "#5D883C",
      bg: "#e2f0db", // light shade of #5D883C
    },
    rejected: {
      text: "#000",
      bg: "#f0f0f0",
    },
  };
  const paginatedData = stableSort(
    tableRowsData,
    getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return (
    <React.Fragment>
      <div className="taskList shadow-lg bg-white">
        {isTabletMid ? (
          <div>
            <div className="grid grid-cols-1 pt-2 pb-2">
              <TableComponent
                numSelected={0}
                searchVal={searchVal}
                // headingText={"Tickets"}
                heading={heading}
                searchBar={false}
                order={order}
                orderBy={orderBy}
                rowCount={paginatedData.length || 0}
                tableRowsData={paginatedData}
                headCells={headers}
                setOrder={setOrder}
                setOrderBy={setOrderBy}
                setSearchVal={setSearchVal}
                rowsPerPage={rowsPerPage}
                getAllData={getListData}
                // componentDisplay={componentDisplay}
              >
                {paginatedData &&
                  paginatedData.length > 0 &&
                  paginatedData.map((data) => (
                    <TableCard
                      key={data.ticketNo}
                      ticketNo={data.ticketNo}
                      requestTitle={data.requestTitle}
                      applicantName={data.applicantName}
                      // requestDescription={data?.requestDescription}
                      requestType={data?.requestType ||data?.privateRequestType}
                      // issueOpen={data.issueOpen}
                      referralName={data?.referralName}
                      // ticketCreatedBy={data.ticketCreatedBy}
                      status={data.ticketStatus}
                      onEdit={(e) => onStepperForm(data, e)}
                      onView={(e) => onStepperForm(data, e)}
                    />
                  ))}
              </TableComponent>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                component="div"
                count={tableRowsData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={"Entries per page: "}
                labelDisplayedRows={({ from, to, count }) =>
                  `Showing ${from}-${to} of ${count} entries`
                }
              />
            </div>
          </div>
        ) : (
          <div>
            <TableComponent
              numSelected={0}
              searchVal={searchVal}
              // headingText={"Tickets"}
              heading={heading}
              order={order}
              searchBar={false}
              orderBy={orderBy}
              rowCount={paginatedData.length || 0}
              tableRowsData={paginatedData}
              headCells={headers}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              setSearchVal={setSearchVal}
              rowsPerPage={rowsPerPage}
              getAllData={getListData}
              // componentDisplay={componentDisplay}
            >
              <TableBody className="table-body">
                {paginatedData &&
                  paginatedData.length > 0 &&
                  stableSort(paginatedData, getComparator(order, orderBy)).map(
                    (row, index) => {
                      return (
                        <TableRow hover tabIndex={-1} key={index}>
                          {headers.map((cell) => {
                            return (
                              <TableCell
                                key={cell.id + index}
                                className={
                                  cell.id === "sno" ? "sno-column" : ""
                                }
                              >
                                {cell.id === "action" ? (
                                  <div className="justify-around items-center flex">
                                    <div
                                      onClick={(e) => {
                                        if (role !== "user") {
                                          onView(row, e);
                                        }
                                      }}
                                      style={{
                                        pointerEvents:
                                          role === "user" ? "none" : "auto",
                                        opacity: role === "user" ? 0.5 : 1,
                                      }}
                                    >
                                      <IoEye size={23} />
                                    </div>
                                  </div>
                                ) : cell.id === "ticketNo" ? (
                                  <div
                                    className="flex cursor-pointer text-blue-800 hover:font-bold"
                                    style={{
                                      pointerEvents:
                                        role === "user" ? "none" : "auto",
                                      // opacity: role === "user" ? 0.5 : 1,
                                    }}
                                  >
                                    <div
                                      onClick={(e) =>
                                        role !== "user" && onStepperForm(row, e)
                                      }
                                    >
                                      {get(row, cell.id)}
                                    </div>
                                  </div>
                                ) : cell.id === "sno" ? (
                                  index + 1
                                ) : cell.id === "ticketStatus" ? (
                                  <div
                                    className={`capitalize rounded-md px-2 py-1 text-sm font-medium`}
                                    style={{
                                      color:
                                        statusStyles[get(row, cell.id)]?.text ||
                                        "",
                                      backgroundColor:
                                        statusStyles[get(row, cell.id)]?.bg ||
                                        "",
                                    }}
                                  >
                                    {get(row, cell.id)}
                                  </div>
                                ) : cell.id === "requestType" ? (
                                  <div className={`capitalize`}>
                                    {get(row, "requestType") || get(row, "privateRequestType")}
                                  </div>
                                ) : cell.id === "assignedTo" ? (
                                  get(row, cell.id)?.split("@")[0]
                                ) : cell.id === "requestTitle" ? (
                                  (() => {
                                    const fullText = get(row, cell.id) || "";
                                
                                    // Capitalize every word
                                    const capitalizeWords = (text) =>
                                      text
                                        .toLowerCase()
                                        .split(" ")
                                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(" ");
                                
                                    const capitalizedFullText = capitalizeWords(fullText);
                                    const displayText =
                                      capitalizedFullText.length > 40
                                        ? capitalizedFullText.slice(0, 40) + "..."
                                        : capitalizedFullText;
                                
                                    return capitalizedFullText.length > 40 ? (
                                      <Tooltip title={capitalizedFullText}>
                                        <span>{displayText}</span>
                                      </Tooltip>
                                    ) : (
                                      <span>{displayText}</span>
                                    );
                                  })()
                                ) : cell.id === "applicantName" ? (
                                  (() => {
                                    const fullText = get(row, cell.id) || "";
                                    const displayText =
                                      fullText.length > 15
                                        ? fullText.slice(0, 15) + "..."
                                        : fullText;

                                    return fullText.length > 15 ? (
                                      <Tooltip title={fullText}>
                                        <span>{displayText}</span>
                                      </Tooltip>
                                    ) : (
                                      <span>{displayText}</span>
                                    );
                                  })()
                                ) : cell.id === "referralName" ? (
                                  (() => {
                                    const fullText = get(row, cell.id) || "";
                                    const displayText =
                                      fullText.length > 15
                                        ? fullText.slice(0, 15) + "..."
                                        : fullText;

                                    return fullText.length > 15 ? (
                                      <Tooltip title={fullText}>
                                        <span>{displayText}</span>
                                      </Tooltip>
                                    ) : (
                                      <span>{displayText}</span>
                                    );
                                  })()
                                ) : (
                                  get(row, cell.id)
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    }
                  )}
              </TableBody>
            </TableComponent>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={tableRowsData?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage={"Entries per page: "}
              labelDisplayedRows={({ from, to, count }) =>
                `Showing ${from}-${to} of ${count} entries`
              }
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
